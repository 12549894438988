import { Link } from "react-router-dom";

import React, { useContext, useEffect, useState } from "react";
import CountUp from "react-countup";
import { Card, CardBody, CardHeader } from "reactstrap";
import UserDataContext from "../../context/Context";

const AutomationBudgetIndicator = ({ budget }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const { companyData } = useContext(UserDataContext);

  const budgetValue = companyData.automationBudget ? companyData.automationBudget.toFixed(2) : 0;

  useEffect(() => {
    setIsAdmin(true);
  }, []);

  return (
    <Card className="h-100">
      <CardHeader className="d-block d-md-flex align-items-center justify-content-between px-2 mb-0 pb-0">
        <h6>Saldo para 🤖</h6>
      </CardHeader>
      <CardBody className="mx-0 pt-0 mb-3 px-2 py-0 d-flex flex-column align-items-start">
        <div className="px-1 mt-0 pt-0 h-100 d-flex flex-column align-items-start justify-content-center w-100">
          <div className="w-100">
            {budgetValue ? (
              <div>
                {isAdmin ? (
                  <div>
                    <p className="h3 pb-0 mb-0 d-flex align-items-center justify-content-start">
                      {/* MX${budgetValue.toLocaleString("es-MX")}{" "} */}

                      <CountUp
                        end={budgetValue}
                        duration={3}
                        prefix="MX$"
                        separator=","
                        decimal="."
                      />
                    </p>
                    <small className="mt-0 pt-0">Información no actualizada en tiempo real*</small>
                  </div>
                ) : (
                  <div className="px-1 mt-0 pt-0">
                    <p className="m-0">N / A</p>
                    <small className="mt-0 pt-0">
                      Solo el administrador global puede ver este dato
                    </small>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <p className="h3 pb-0 mb-0">MX$0.00</p>
                <p>
                  <div className="mt-0 pt-0">Ingresa saldo</div>
                  para que tus automatizaciones se ejecuten 🤖
                </p>
              </div>
            )}
          </div>

          <Link to="/automations/all" className="p-0">
            Ir a Automatizaciones
          </Link>
        </div>
      </CardBody>
    </Card>
  );
};

export default AutomationBudgetIndicator;
