import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStripe } from "@stripe/react-stripe-js";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import Flex from "../common/Flex";

import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import Lottie from "react-lottie";
import { NavLink, withRouter } from "react-router-dom";
import {
  NavLink as BootstrapNavLink,
  Button,
  Collapse,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
} from "reactstrap";
import LoaderLottie from "../../assets/lotties/loader_v2.json";
import "../../assets/scss/_user.scss";
import AppContext from "../../context/Context";
import { auth, createCustomerPortalSession } from "../../index";
import NavbarVerticalMenuItem from "./NavbarVerticalMenuItem";
import UpgradePlanMenuItem from "./UpgradePlanMenuItem";

const NavbarVerticalMenu = ({
  routes,
  location,
  permissionLevel,
  billingUser,
  companyPlan,
  isPos,
}) => {
  const firestore = firebase.firestore();
  const [openedIndex, setOpenedIndex] = useState(null);
  const [isBillingUser, setIsBillingUser] = useState(false);
  const stripe = useStripe();
  const { setShowBurgerMenu } = useContext(AppContext);

  const [modal, setModal] = useState(false);

  const [stripeCustomer, setCustomer] = useState("");

  let companyPlanValue = 0;

  if (companyPlan) {
    const planValues = {
      Basico: 1,
      Estandar: 2,
      Premium: 3,
    };
    companyPlanValue = planValues[companyPlan] || 0;
  }

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: LoaderLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    console.log("companyPlanValue", companyPlanValue);
  }, [companyPlanValue]);

  const openPortal = async () => {
    const portalSession = await createCustomerPortalSession();
    if (portalSession.data.url) {
      window.location.assign(portalSession.data.url);
    }
  };

  useEffect(() => {
    if (billingUser) {
      if (billingUser === auth.currentUser.uid) {
        firestore
          .collection("posUsers")
          .doc(billingUser)
          .get()
          .then((doc) => {
            setCustomer(doc.data().stripeId);
          });
        setIsBillingUser(true);
      } else {
        setIsBillingUser(false);
      }
    } else {
      setIsBillingUser(false);
    }

    let openedDropdown = null;
    routes.forEach((route, index) => {
      if (location.pathname.indexOf(route.to) === 0) openedDropdown = index;
    });

    setOpenedIndex(openedDropdown);
    // eslint-disable-next-line
  }, []);

  const toggleOpened = (e, index) => {
    e.preventDefault();
    return setOpenedIndex(openedIndex === index ? null : index);
  };

  const dividerFormatter = (divider) => {
    if (divider === true) {
      return (
        <div className="navbar-vertical-divider">
          <hr className="navbar-vertical-hr my-2" />
        </div>
      );
    }
  };

  return routes.map((route, index) => {
    const meetsPosRestriction = !route.requiresPos || (route.requiresPos && isPos);

    if (route.name === "Pagos") {
      if (isBillingUser) {
        return (
          <NavItem key={index}>
            <Modal centered={true} isOpen={modal}>
              <ModalHeader className="text-center d-flex align-items-center justify-content-center">
                <h3 className="w-100 text-center d-flex align-items-center justify-content-center">
                  Abriendo Portal de Pagos
                </h3>
              </ModalHeader>
              <ModalBody>
                <Lottie options={lottieOptions} height={100} width={100} speed={2} />
              </ModalBody>
            </Modal>
            <BootstrapNavLink
              onClick={(e) => toggleOpened(e, index)}
              className="dropdown-indicator cursor-pointer"
              aria-expanded={openedIndex === index}
            >
              <NavbarVerticalMenuItem route={route} />
            </BootstrapNavLink>

            <Collapse isOpen={openedIndex === index}>
              <Nav>
                <NavbarVerticalMenu
                  routes={route.children}
                  location={location}
                  permissionLevel={permissionLevel}
                  companyPlan={companyPlan}
                  isPos={isPos}
                />
                <Button
                  className="btn-link text-left font-weight-medium portalBtn"
                  onClick={(e) => {
                    e.preventDefault();
                    setModal(true);
                    openPortal();
                  }}
                >
                  Portal de Pagos
                </Button>
              </Nav>
            </Collapse>
            {dividerFormatter(route.divider)}
          </NavItem>
        );
      }
    } else if (route.name === "Recomienda Swirvle") {
      return (
        <NavItem key={index}>
          <NavLink
            className="nav-link referral-link"
            {...route}
            onClick={() => setShowBurgerMenu(false)}
          >
            <Flex align="center">
              {route.icon && (
                <span className="nav-link-icon">
                  <FontAwesomeIcon icon={route.icon} />
                </span>
              )}
              <span className="nav-link-text">{route.name}</span>
            </Flex>
          </NavLink>
          {dividerFormatter(route.divider)}
        </NavItem>
      );
    } else {
      if (permissionLevel >= route.permissionLevelRequired && meetsPosRestriction) {
        if (companyPlanValue >= route.planRequired) {
          if (!route.children) {
            return (
              <NavItem key={index}>
                <NavLink className="nav-link" {...route} onClick={() => setShowBurgerMenu(false)}>
                  <NavbarVerticalMenuItem route={route} />
                </NavLink>
                {dividerFormatter(route.divider)}
              </NavItem>
            );
          }

          return (
            <NavItem key={index}>
              <BootstrapNavLink
                onClick={(e) => toggleOpened(e, index)}
                className="dropdown-indicator cursor-pointer"
                aria-expanded={openedIndex === index}
              >
                <NavbarVerticalMenuItem route={route} />
              </BootstrapNavLink>
              <Collapse isOpen={openedIndex === index}>
                <Nav>
                  <NavbarVerticalMenu
                    routes={route.children}
                    location={location}
                    permissionLevel={permissionLevel}
                    companyPlan={companyPlan}
                    isPos={isPos}
                  />
                </Nav>
              </Collapse>
              {dividerFormatter(route.divider)}
            </NavItem>
          );
        } else {
          if (!route.children) {
            return (
              <NavItem key={index} className="">
                <NavLink
                  className="nav-link"
                  to={"/up/" + route.upsell}
                  onClick={() => setShowBurgerMenu(false)}
                >
                  <UpgradePlanMenuItem route={route} />
                </NavLink>
                {dividerFormatter(route.divider)}
              </NavItem>
            );
          }

          return (
            <NavItem key={index}>
              <BootstrapNavLink
                onClick={(e) => toggleOpened(e, index)}
                className="dropdown-indicator cursor-pointer"
                aria-expanded={openedIndex === index}
              >
                <div className="nav-link pt-0 pb-0">
                  <UpgradePlanMenuItem route={route} />
                </div>
              </BootstrapNavLink>
              <Collapse isOpen={openedIndex === index}>
                <Nav>
                  <NavbarVerticalMenu
                    routes={route.children}
                    location={location}
                    permissionLevel={permissionLevel}
                    companyPlan={companyPlan}
                    isPos={isPos}
                  />
                </Nav>
              </Collapse>
              {dividerFormatter(route.divider)}
            </NavItem>
          );
        }
      }
    }
  });
};

NavbarVerticalMenu.propTypes = {
  routes: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(NavbarVerticalMenu);
