import { faFolders, faScanner } from "@fortawesome/pro-solid-svg-icons";

//Planes de suscripción
const basico = 1;
const estandar = 2;
const premium = 3;

export const homeRoutes = {
  name: "Inicio",
  to: "/",
  // exact: true,
  permissionLevelRequired: 2,
  icon: "home",
  planRequired: basico,

  children: [
    //{ to: '/pages/events', name: 'Eventos' },
    //{ to: '/pages/event-create', name: 'Crear evento'},
    {
      to: "/",
      name: "Tablero",
      permissionLevelRequired: 2,
      planRequired: basico,
    },
    {
      to: "/statistics",
      name: "Estadísticas",
      permissionLevelRequired: 2,
      planRequired: basico,
    },
    {
      to: "/visits",
      name: "Visitas recientes",
      permissionLevelRequired: 3,
      planRequired: basico,
    },
  ],

  // children: [
  //  //{ to: '/pages/events', name: 'Eventos' },
  //  //{ to: '/pages/event-create', name: 'Crear evento'},
  //  {
  //    to: "/landing",
  //    name: "Página de inicio",
  //    permissionLevelRequired: 2,
  //  },
  //  {
  //    to: "/",
  //    name: "Tablero",
  //    permissionLevelRequired: 2,
  // }
  //  ]
};

export const landingRoutes = {
  name: "Inicio",
  to: "/",
  // exact: true,
  permissionLevelRequired: 2,
  planRequired: basico,
  // icon: "chart-pie",
  children: [
    //{ to: '/pages/events', name: 'Eventos' },
    //{ to: '/pages/event-create', name: 'Crear evento'},
    {
      to: "/landing",
      name: "Página de inicio",
      permissionLevelRequired: 2,
      planRequired: basico,
    },
    {
      to: "/",
      name: "Tablero",
      permissionLevelRequired: 2,
      planRequired: basico,
    },
  ],
};

export const marketingRoutes = {
  name: "Marketing",
  to: "/marketing",
  icon: ["fas", "bullhorn"],
  permissionLevelRequired: 2,
  planRequired: basico,
  children: [
    //{ to: '/pages/events', name: 'Eventos' },
    //{ to: '/pages/event-create', name: 'Crear evento'},
    {
      to: "/marketing/customers",
      name: "Clientes",
      permissionLevelRequired: 2,
      planRequired: basico,
    },
    {
      to: "/marketing/campaigns",
      name: "Marketing Directo",
      permissionLevelRequired: 3,
      planRequired: basico,
      // badge: {
      //   text: "Beta",
      //   color: "light",
      // },
    },
  ],
};

export const catalogRoutes = {
  name: "Inventario",
  to: "/inventory",
  icon: ["fas", "boxes"],
  permissionLevelRequired: 3,
  planRequired: estandar,
  children: [
    {
      to: "/inventory/products",
      name: "Productos",
      permissionLevelRequired: 3,
      planRequired: estandar,
      upsell: "productos",
    },
    {
      to: "/inventory/categories",
      name: "Categorías",
      permissionLevelRequired: 3,
      planRequired: estandar,
      requiresPos: true,
    },
    {
      to: "/inventory/suppliers",
      name: "Proveedores",
      permissionLevelRequired: 3,
      planRequired: estandar,
      requiresPos: true,
    },
    {
      to: "/inventory/brands",
      name: "Marcas",
      permissionLevelRequired: 3,
      planRequired: estandar,
      requiresPos: true,
    },
  ],
};

export const surveyRoutes = {
  name: "Encuestas",
  to: "/survey",
  permissionLevelRequired: 3,
  planRequired: premium,
  upsell: "encuestas",
  divider: true,
  icon: "poll",
};

export const autoRoutes = {
  name: "Automatizaciones",
  to: "/automations",
  divider: true,
  icon: ["fas", "robot"],
  permissionLevelRequired: 3,
  planRequired: basico,
  children: [
    {
      to: "/automations/all",
      name: "Automatizaciones",
      permissionLevelRequired: 3,
      planRequired: basico,
    },
    {
      to: "/automations/log",
      name: "Historial",
      permissionLevelRequired: 3,
      planRequired: basico,
    },
    // {
    //   to: "/automations/settings",
    //   name: "Ajustes",
    //   permissionLevelRequired: 3,
    //   planRequired: basico,
    // },
  ],
};
export const scanRoutes = {
  name: "Escaneo",
  to: "/scan",
  divider: false,
  permissionLevelRequired: 2,
  planRequired: basico,
  icon: faScanner,
};

// export const herramientasRoutes = {
//   name: 'Herramientas',
//   to: '/ajustes',
//   icon: ['fas','tools'],
//   children: [
//     { to: '/kanban', name: 'Kanban' },
//    // { to: '/chat', name: 'Chat' }
//   ]
// };

export const facturasRoutes = {
  name: "Facturación",
  to: "/ajustes",
  icon: "file-excel",
  children: [
    { to: "/kanban", name: "Todas las facturas" },
    { to: "/kanban", name: "Configuración" },
  ],
};

export const lealtadRoutes = {
  name: "Lealtad",
  to: "/lealtad",
  permissionLevelRequired: 3,
  planRequired: basico,
  divider: false,
  icon: "crown",
  children: [
    {
      to: "/lealtad/visitas",
      name: "Por Visitas",
      permissionLevelRequired: 3,
      planRequired: basico,
    },

    {
      to: "/lealtad/productos",
      name: "Por Producto",
      upsell: "por-producto",
      permissionLevelRequired: 3,
      planRequired: estandar,
    },
    {
      to: "/lealtad/puntos",
      name: "Por Puntos",
      upsell: "por-puntos",
      permissionLevelRequired: 3,
      planRequired: estandar,
    },
    {
      to: "/lealtad/settings",
      name: "Configuración",
      permissionLevelRequired: 3,
      planRequired: basico,
    },
    // { to: '/lealtad/puntos', name: 'Por puntos' },
    //{ to: "/lealtad/cupones", name: "Cupones", permissionLevelRequired: 3, },
  ],
};

export const promoRoutes = {
  name: "Cupones",
  to: "/promotions",
  permissionLevelRequired: 3,
  upsell: "cupones",
  planRequired: estandar,
  icon: "percent",
  children: [
    {
      to: "/promotions/coupons",
      name: "Inteligentes",
      upsell: "cupones-inteligentes",
      permissionLevelRequired: 3,
      planRequired: estandar,
    },
    {
      to: "/promotions/birthday",
      name: "Cumpleaños",
      upsell: "cupones-cumpleanos",
      permissionLevelRequired: 3,
      planRequired: premium,
    },
  ],
};

export const AppRoutes = {
  name: "App",
  to: "/storefront",
  divider: true,
  permissionLevelRequired: 2,
  icon: "tablet-alt",
  planRequired: basico,
};

/*
export const ECommerceRoutes = {
  name: 'E commerce',
  to: '/e-commerce',
  icon: 'cart-plus',
  children: [
    { to: '/e-commerce/products/list', name: 'Productos' },
    { to: '/e-commerce/orders', name: 'Ordenes' },
  ]
};*/

// export const authenticationRoutes = {
//   name: 'Authentication',
//   to: '/authentication',
//   icon: 'lock',
//   children: [
//     {
//       to: '/authentication/basic',
//       name: 'Basic',
//       children: [
//         { to: '/authentication/basic/login', name: 'Login' },
//         { to: '/authentication/basic/logout', name: 'Logout' },
//         { to: '/authentication/basic/register', name: 'Register' },
//         { to: '/authentication/basic/forget-password', name: 'Forgot password' },
//         { to: '/authentication/basic/password-reset', name: 'Reset password' },
//         { to: '/authentication/basic/confirm-mail', name: 'Confirm mail' },
//         { to: '/authentication/basic/lock-screen', name: 'Lock screen' }
//       ]
//     },
//     {
//       to: '/authentication/card',
//       name: 'Card',
//       children: [
//         { to: '/authentication/card/login', name: 'Login' },
//         { to: '/authentication/card/logout', name: 'Logout' },
//         { to: '/authentication/card/register', name: 'Register' },
//         { to: '/authentication/card/forget-password', name: 'Forgot password' },
//         { to: '/authentication/card/password-reset', name: 'Reset password' },
//         { to: '/authentication/card/confirm-mail', name: 'Confirm mail' },
//         { to: '/authentication/card/lock-screen', name: 'Lock screen' }
//       ]
//     },
//     {
//       to: '/authentication/split',
//       name: 'Split',
//       children: [
//         { to: '/authentication/split/login', name: 'Login' },
//         { to: '/authentication/split/logout', name: 'Logout' },
//         { to: '/authentication/split/register', name: 'Register' },
//         { to: '/authentication/split/forget-password', name: 'Forgot password' },
//         { to: '/authentication/split/password-reset', name: 'Reset password' },
//         { to: '/authentication/split/confirm-mail', name: 'Confirm mail' },
//         { to: '/authentication/split/lock-screen', name: 'Lock screen' }
//       ]
//     },
//     {
//       to: '/authentication/wizard',
//       name: 'Wizard'
//     }
//   ]
// };

// export const pageRoutes = {
//   name: 'Pages',
//   to: '/pages',
//   icon: 'copy',
//   children: [
//     { to: '/pages/activity', name: 'Activity' },
//     { to: '/pages/associations', name: 'Associations' },
//     { to: '/pages/billing', name: 'Billing' },
//     { to: '/pages/customer-details', name: 'Customer details' },
//     { to: '/pages/event-detail', name: 'Event detail' },
//     { to: '/pages/event-create', name: 'Event create' },
//     { to: '/pages/faq', name: 'Faq' },
//     { to: '/pages/invoice', name: 'Invoice' },
//     { to: '/pages/invite-people', name: 'Invite people' },
//     { to: '/pages/notifications', name: 'Notifications' },
//     { to: '/pages/pricing', name: 'Pricing' },
//     { to: '/pages/pricing-alt', name: 'Pricing alt' },
//     { to: '/pages/profile', name: 'Profile' },
//     { to: '/pages/settings', name: 'Settings' }
//   ]
// };
// export const widgetsRoutes = {
//   name: 'Widgets',
//   to: '/widgets',
//   exact: true,
//   icon: 'poll'
// };

export const pagoRoutes = {
  name: "Pagos",
  to: "/pagos",
  icon: "file-invoice-dollar",
  permissionLevelRequired: 3,
  planRequired: basico,
  children: [
    //{ to: '/pagos/historial', name: 'Historial', permissionLevelRequired: 3 },
    // {
    //   to: "/pagos/formas-de-pago",
    //   name: "Formas de Pago",
    //   permissionLevelRequired: 3,
    // },
    {
      to: "/pagos/facturacion",
      name: "Datos de Facturación",
      permissionLevelRequired: 3,
      planRequired: basico,
    },
    //{ to: '/pagos/datos-facturacion', name: 'Datos Facturación', permissionLevelRequired: 3 },
  ],
};

export const ayudaRoutes = {
  name: "Ayuda",
  to: "/documentation",
  permissionLevelRequired: 2,
  planRequired: basico,
  icon: "question-circle",
  children: [
    {
      to: "/tutoriales",
      name: "Tutoriales",
      permissionLevelRequired: 2,
      planRequired: basico,
    },
    {
      to: "/pages/faq",
      name: "FAQ",
      permissionLevelRequired: 2,
      planRequired: basico,
    },
    {
      to: "/help",
      name: "Soporte",
      permissionLevelRequired: 2,
      planRequired: basico,
    },
  ],
};

export const shareRoutes = {
  name: "Media / Recursos",
  to: "/share-section",
  divider: false,
  icon: faFolders,
  permissionLevelRequired: 3,
  planRequired: basico,
  // children: [
  //   {
  //     to: "/automations/all",
  //     name: "Automatizaciones",
  //     permissionLevelRequired: 3,
  //     planRequired: basico,
  //   },
  //   {
  //     to: "/automations/settings",
  //     name: "Ajustes",
  //     permissionLevelRequired: 3,
  //     planRequired: basico,
  //   },
  // ],
};
export const referralRoutes = {
  name: "Recomienda Swirvle",
  to: "/referral",
  permissionLevelRequired: 2,
  icon: "share",
  planRequired: basico,
};

export const partnerRoutes = {
  name: "Convenios",
  to: "/pages/associations",
  permissionLevelRequired: 2,
  icon: "handshake",
  planRequired: basico,
};

/*
export const chatRoutes = {
  name: 'Chat',
  to: '/chat',
  exact: true,
  icon: 'comments'
};

export const kanbanRoutes = {
  name: 'Kanban',
  to: '/kanban',
  exact: true,
  icon: ['fab', 'trello']
};

export const emailRoutes = {
  name: 'Email',
  to: '/email',
  icon: 'envelope-open',
  children: [
    { to: '/email/inbox', name: 'Inbox' },
    { to: '/email/compose', name: 'Escribir' }
  ]
};

export const documentationRoutes = {
  name: 'Documentation',
  to: '/documentation',
  exact: true,
  icon: 'book'
};*/

// export const componentRoutes = {
//   name: 'Components',
//   to: '/components',
//   icon: 'puzzle-piece',
//   children: [
//     { to: '/components/alerts', name: 'Alerts' },
//     { to: '/components/accordions', name: 'Accordions' },
//     { to: '/components/avatar', name: 'Avatar' },
//     { to: '/components/badges', name: 'Badges' },
//     { to: '/components/backgrounds', name: 'Backgrounds' },
//     { to: '/components/breadcrumb', name: 'Breadcrumb' },
//     { to: '/components/buttons', name: 'Buttons' },
//     { to: '/components/cards', name: 'Cards' },
//     {
//       to: '/components/cookie-notice',
//       name: 'Cookie notice'
//     },
//     { to: '/components/collapses', name: 'Collapses' },
//     {
//       to: '/components/carousel',
//       name: 'Carousel'
//     },
//     { to: '/components/dropdowns', name: 'Dropdowns' },
//     { to: '/components/forms', name: 'Forms' },
//     { to: '/components/listgroups', name: 'List groups' },
//     { to: '/components/modals', name: 'Modals' },
//     { to: '/components/navs', name: 'Navs' },

//     {
//       to: '/components',
//       name: 'Navbar',
//       children: [
//         { to: '/components/navbars', name: 'Default' },
//         {
//           to: '/components/navbar-vertical',
//           name: 'Vertical',
//           badge: {
//             text: 'New'
//           }
//         },
//         {
//           to: '/components/navbar-top',
//           name: 'Top'
//         }
//       ]
//     },
//     { to: '/components/pageheaders', name: 'Page headers' },
//     { to: '/components/paginations', name: 'Paginations' },
//     { to: '/components/popovers', name: 'Popovers' },
//     { to: '/components/progress', name: 'Progress' },
//     {
//       to: '/components/sidepanel',
//       name: 'Sidepanel'
//     },
//     {
//       to: '/components/spinners',
//       name: 'Spinners'
//     },

//     { to: '/components/tables', name: 'Tables' },
//     { to: '/components/tooltips', name: 'Tooltips' }
//   ]
// };

// export const pluginRoutes = {
//   name: 'Plugins',
//   to: '/plugins',
//   icon: 'plug',
//   children: [
//     { to: '/plugins/bulk-select', name: 'Bulk select' },
//     {
//       to: '/plugins/react-beautiful-dnd',
//       name: 'Beautiful DnD'
//     },
//     {
//       to: '/plugins',
//       name: 'Chart',
//       children: [{ to: '/plugins/chart', name: 'Chart Js' }, { to: '/plugins/echarts', name: 'Echarts' }]
//     },
//     { to: '/plugins/countup', name: 'Countup' },
//     { to: '/plugins/code-highlight', name: 'Code Highlight' },
//     { to: '/plugins/datetime', name: 'Datetime' },
//     { to: '/plugins/dropzone', name: 'Dropzone' },
//     { to: '/plugins/emoji-mart', name: 'Emoji Mart' },
//     { to: '/plugins/fontawesome', name: 'Fontawesome' },
//     { to: '/plugins/image-lightbox', name: 'Image lightbox' },
//     { to: '/plugins/lottie', name: 'Lottie' },
//     {
//       to: '/plugins',
//       name: 'Map',
//       children: [
//         { to: '/plugins/leaflet-map', name: 'Leaflet map' },
//         { to: '/plugins/google-map', name: 'Google map' },
//         { to: '/plugins/echart-map', name: 'Echart Map' }
//       ]
//     },
//     { to: '/plugins/plyr', name: 'Plyr' },
//     { to: '/plugins/progressbar', name: 'Progressbar' },
//     { to: '/plugins/react-hook-form', name: 'React Hook Form' },
//     {
//       to: '/plugins/react-bootstrap-table2',
//       name: 'BS Table2'
//     },
//     { to: '/plugins/select', name: 'Select' },
//     { to: '/plugins/slick-carousel', name: 'Slick Carousel' },
//     { to: '/plugins/scroll-bar', name: 'Scroll Bar' },
//     { to: '/plugins/toastify', name: 'Toastify' },
//     { to: '/plugins/typed', name: 'Typed' },
//     { to: '/plugins/wysiwyg', name: 'WYSIWYG editor' }
//   ]
// };

// export const utilityRoutes = {
//   name: 'Utilities',
//   to: '/utilities',
//   icon: ['fab', 'hotjar'],
//   children: [
//     { to: '/utilities/borders', name: 'Borders' },
//     { to: '/utilities/clearfix', name: 'Clearfix' },
//     { to: '/utilities/closeIcon', name: 'Close icon' },
//     { to: '/utilities/colors', name: 'Colors' },
//     { to: '/utilities/display', name: 'Display' },
//     { to: '/utilities/embed', name: 'Embed' },
//     { to: '/utilities/figures', name: 'Figures' },
//     { to: '/utilities/flex', name: 'Flex' },
//     { to: '/utilities/grid', name: 'Grid' },
//     { to: '/utilities/sizing', name: 'Sizing' },
//     { to: '/utilities/spacing', name: 'Spacing' },
//     { to: '/utilities/stretchedLink', name: 'Stretched link' },
//     { to: '/utilities/typography', name: 'Typography' },
//     { to: '/utilities/verticalAlign', name: 'Vertical align' },
//     { to: '/utilities/visibility', name: 'Visibility' }
//   ]
// };

export const posRoutes = {
  name: "Agregar POS",
  to: "/pos-add",
  exact: true,
  icon: "plus",
};

export const miCuentaRoutes = {
  name: "Mi Cuenta",
  to: "/ajustes",
  permissionLevelRequired: 2,
  planRequired: basico,
  divider: true,
  icon: "user-circle",
  children: [
    {
      to: "/ajustes/general",
      name: "Ajustes",
      permissionLevelRequired: 2,
      planRequired: basico,
    },
  ],
};

export const miEmpresaRoutes = {
  name: "Mi Negocio",
  to: "/store",
  permissionLevelRequired: 3,
  planRequired: basico,
  icon: "briefcase",
};

export const sucursalesRoutes = {
  name: "Sucursales",
  to: "/store/locations",
  permissionLevelRequired: 3,
  planRequired: basico,
  icon: "store",
};

export const miEquipoRoutes = {
  name: "Mi Equipo",
  to: "/store/team",
  permissionLevelRequired: 3,
  planRequired: basico,
  icon: "user-friends",
  divider: true,
};

export const buyRoutes = {
  name: "Comprar",
  to: "/buy",
  permissionLevelRequired: 3,
  planRequired: basico,
  icon: "shopping-cart",
};

export default [
  homeRoutes,
  marketingRoutes,
  lealtadRoutes,
  promoRoutes,
  catalogRoutes,
  surveyRoutes,
  autoRoutes,
  scanRoutes,
  AppRoutes,
  miEmpresaRoutes,
  sucursalesRoutes,
  miEquipoRoutes,
  shareRoutes,
  // buyRoutes,
  miCuentaRoutes,
  ayudaRoutes,
  pagoRoutes,
  referralRoutes,
  partnerRoutes,
  // herramientasRoutes,
  //facturasRoutes, No borrar, upcoming feature
  //posRoutes,
  //landingRoutes
  //emailRoutes,
  //  ECommerceRoutes,
  // pageRoutes,
  // chatRoutes,
  // kanbanRoutes,
  // authenticationRoutes,
  // widgetsRoutes,
  // componentRoutes,
  // utilityRoutes,
  // pluginRoutes,
  // documentationRoutes,
];
