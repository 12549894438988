import React, { Fragment, useContext, useEffect, useState } from "react";
import Lottie from "react-lottie";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

// import cupones from "../../assets/img/cup.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import LoaderLottie from "../../assets/lotties/loader_v2.json";
import AppContext from "../../context/Context";
import { auth, createCustomerPortalSession } from "../../index";

const UpsellBtn = ({ classes, text }) => {
  const { companyData } = useContext(AppContext);
  const [modal, setModal] = useState(false);
  const [showBtn, setShowBtn] = useState(false);

  const openPortal = async () => {
    try {
      const portalSession = await createCustomerPortalSession();
      if (portalSession.data.url) {
        window.location.assign(portalSession.data.url);
      }
    } catch (error) {
      setModal(false);
      console.log(error);
      toast.error(
        "Error, asegurate de estar logueado con la cuenta de la persona quien hizo el registro"
      );
    }
  };
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: LoaderLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    let userId = auth.currentUser.uid;
    if (companyData) {
      companyData.members.forEach((member) => {
        if (member.userUID === userId) {
          if (member.permissionLevel >= 3 && companyData.plan !== "Premium") {
            setShowBtn(true);
          }
        }
      });
    }
  }, [window.location.pathname]);

  return (
    <Fragment>
      <Modal centered={true} isOpen={modal}>
        <ModalHeader className="text-center d-flex align-items-center justify-content-center">
          <h3 className="w-100 text-center d-flex align-items-center justify-content-center">
            Abriendo Portal de Pagos
          </h3>
        </ModalHeader>
        <ModalBody>
          <Lottie options={lottieOptions} height={100} width={100} speed={2} />
        </ModalBody>
      </Modal>

      {showBtn && (
        <Button
          className={classes}
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            setModal(true);
            openPortal();
          }}
        >
          <FontAwesomeIcon icon="crown" className="mr-2" />
          {text}
        </Button>
      )}
    </Fragment>
  );
};

export default UpsellBtn;
