import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Collapse, Nav, Navbar, NavItem } from "reactstrap";
import { navbarBreakPoint, topNavbarBreakpoint } from "../../config";
import AppContext from "../../context/Context";
import { auth } from "../../index";
import LocationSelect from "./LocationSelect";
import Logo from "./Logo";
import NavbarTopDropDownMenus from "./NavbarTopDropDownMenus";
import TopNavRightSideNavItem from "./TopNavRightSideNavItem";

const NavbarTop = (props) => {
  const { showBurgerMenu, setShowBurgerMenu, isTopNav } = useContext(AppContext);
  const [navbarCollapsed, setNavbarCollapsed] = useState(false);
  const { permissionLevel } = props;
  const { isDark, companyData } = useContext(AppContext);

  const [showBtn, setShowBtn] = useState(false);

  useEffect(() => {
    let userId = auth.currentUser.uid;
    if (companyData) {
      companyData.members.forEach((member) => {
        if (member.userUID === userId) {
          if (member.permissionLevel >= 2) {
            setShowBtn(true);
          }
        }
      });
    }
  }, [window.location.pathname]);
  return (
    <Navbar
      light
      className="navbar-glass fs--1 font-weight-semi-bold row navbar-top sticky-kit"
      expand={isTopNav ? topNavbarBreakpoint : navbarBreakPoint}
    >
      <div
        className={classNames("toggle-icon-wrapper mr-md-3 mr-2", {
          "d-lg-none": isTopNav,
          [`d-${navbarBreakPoint}-none`]: !isTopNav,
        })}
      >
        <button
          className="navbar-toggler-humburger-icon btn btn-link d-flex align-item-center justify-content-center "
          onClick={() => {
            !isTopNav ? setShowBurgerMenu(!showBurgerMenu) : setNavbarCollapsed(!navbarCollapsed);
          }}
          id="burgerMenu"
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </button>
      </div>
      <Logo
        at="navbar-top"
        width={40}
        id="topLogo"
        cllname={`${!isTopNav ? "text-dark" : "text-dark"}`}
      />
      <Collapse navbar isOpen={navbarCollapsed} className="scrollbar">
        {!isTopNav ? (
          <Nav navbar className="align-items-center d-none d-lg-block">
            {/*
            <NavItem>
              <SearchBox />
            </NavItem> 
            */}
            <NavItem className="d-flex">
              <LocationSelect />
              {window.innerWidth > 768 && showBtn && (
                <div className="ml-3 d-flex">
                  <Link to="/referral" className="d-flex align-items-center justify-content-center">
                    <FontAwesomeIcon icon="share" className="mr-2" />
                    ¡Recomienda Swirvle y Gana!
                  </Link>
                </div>
              )}
            </NavItem>
          </Nav>
        ) : (
          <Nav navbar>
            <NavbarTopDropDownMenus setNavbarCollapsed={setNavbarCollapsed} />
          </Nav>
        )}
      </Collapse>
      <TopNavRightSideNavItem name={props.name} />
    </Navbar>
  );
};

export default NavbarTop;
